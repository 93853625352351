import NestedForm from "stimulus-rails-nested-form";

export default class extends NestedForm {
  static targets = ["formTemplate", "itemsList"];
  static values = {
    patternToReplaceWithIndex: String,
  };

  connect() {
    super.connect();
    console.log("Do what you want here.");
  }

  add(e) {
    e.preventDefault();
    e.stopPropagation();

    this.itemsListTarget.insertAdjacentHTML("beforeend", this.generateFormHTML());
  }

  generateFormHTML() {
    const html = this.formTemplateTarget.innerHTML.toString();
    return html.replaceAll(this.patternToReplaceWithIndexValue, new Date().getTime());
  }
}
